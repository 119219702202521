import React from 'react';
import styled from 'styled-components/macro';
import { UHCDarkText } from '@rally/rewards-view/color';

const updated = '10.16.2023';
const CMS = 'Y0066_220831_031533';
const CMS2023 = 'Y0066_231011_022840_M';

const Legal = () => (
  <LegalContainer>
    <Text>&copy; 2022 United Heathcare Services, Inc. All rights reserved.</Text>
    <Text>
      Information last updated: {updated} | {CMS}
    </Text>
  </LegalContainer>
);

const Legal2023CopyUpdate = () => (
  <LegalContainer>
    <Text>&copy; 2023 United Heathcare Services, Inc. All rights reserved.</Text>
    <Text>
      Information last updated: {updated} | {CMS2023}
    </Text>
  </LegalContainer>
);

const LegalContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  padding-right: 50px;
`;

const Text = styled.p`
  color: ${UHCDarkText};
  font-size: 13px;
  font-weight: normal;
  line-height: 16px;
  margin: 4px 0;
`;

export { Legal, Legal2023CopyUpdate };
