// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React from 'react';
import { connect, shallowEqual, useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components/macro';
import { page } from '@rally/renew-active/hoc';
import {
  DesktopLocation,
  GymSearchResult,
  MobileLocation,
  MobileSearchSortDropdown,
  PVModalFitnessContent,
  SearchFindPlanBox,
  SearchNoResultsBox,
  SearchNoResultsSection,
  SearchSortDropdown,
  wireGymSearch,
} from '@rally/renew-active/component';
import { searchGyms } from '@rally/renew-active/api';
import { AppState, SearchFormActions } from '@rally/renew-active/store';
import { SORT_OPTION_VALUES } from '@rally/renew-active/util';
import { UHCLinkV2 } from '@rally/rewards-view/color';
import { useMatchMedia } from '@rally/rewards-view/hook';
import { breakpoint } from '@rally/rewards-util';
import { gymSearchHeaderText, mobileGymSearchHeaderText } from '../constants/searchText';
import PagePath from '../constants/PagePath';

const {
  GymMobileSearchFilterModal,
  GymSearchFilter,
  GymSearchForm,
  GymSearchMap,
  GymSearchResults,
  GymSearchResultsFooter,
  GymSearchResultsHeader,
  GymLocationSidebar,
} = wireGymSearch<AppState>(connect, withRouter);

const useDropdownHook = () => {
  const dispatch = useDispatch();
  const sortOption = useSelector((state: AppState) => state.searchForm.sort, shallowEqual);

  return {
    currentValue: sortOption,
    cb: (v: typeof sortOption) => {
      dispatch(SearchFormActions.sortResults(v));
    },
  };
};

const GymSearchSortDropdown = () => (
  <SearchSortDropdown dropdownHook={useDropdownHook} sortValues={SORT_OPTION_VALUES} />
);

const GymMobileSearchSortDropdown = () => (
  <MobileSearchSortDropdown dropdownHook={useDropdownHook} sortValues={SORT_OPTION_VALUES} />
);

const SearchResultsHeader: React.FC = () => {
  return <GymSearchResultsHeader SearchSortDropdown={GymSearchSortDropdown} />;
};

const MobileSearchFilterModal: React.FC = () => {
  return (
    <GymMobileSearchFilterModal MobileSearchSortDropdown={GymMobileSearchSortDropdown} SearchFilter={GymSearchFilter} />
  );
};

/**
 * Created wrapper components for SearchNoResultsBox and SearchNoResultsSection because we have added default props.
 * Now either we can accept props in SearchResults Component as SearchNoResultsBox: SearchNoResultsBox<any> or SearchNoResultsBox: SearchNoResultsBox<exactExpectingType>
 * But if we do that way , we are restricting the consumer to send a particular type of component, which is not the purpose of this design
 * Consumer should be able to send any component as this is just a way of assembling the components.
 * So we created we wrapper components with no props so that we don't need any type change
 */

const ProspectSearchNoResultsBox = () => <SearchNoResultsBox />;

const ProspectSearchNoResultsSection = () => <SearchNoResultsSection />;

const ProspectSearchFindPlanBox = () => {
  return (
    <SearchFindPlanBox
      text="Choose a Medicare plan with Renew Active®. The gold standard in Medicare fitness programs for body and mind."
      title="Stay active. Stay focused. Stay you."
    />
  );
};
const SearchResults: React.FC = () => {
  return (
    <GymSearchResults
      MobileSearchFilterModal={MobileSearchFilterModal}
      PrintableViewModalContent={PVModalFitnessContent}
      ResultToComponent={GymSearchResult}
      SearchFindPlanBox={ProspectSearchFindPlanBox}
      SearchNoResultsBox={ProspectSearchNoResultsBox}
      SearchNoResultsSection={ProspectSearchNoResultsSection}
      SearchResultsFooter={GymSearchResultsFooter}
      SearchResultsHeader={SearchResultsHeader}
    />
  );
};

const SearchForm = () => (
  <GymSearchForm
    fetchResults={searchGyms}
    path={PagePath.GymSearch}
    text={{
      title: 'Search',
      subtitle: 'Location',
    }}
  />
);

const LocationSidebar: React.FC = () => {
  return (
    <GymLocationSidebar
      SearchFilter={GymSearchFilter}
      SearchFindPlanBox={ProspectSearchFindPlanBox}
      SearchForm={SearchForm}
      SearchNoResultsBox={ProspectSearchNoResultsBox}
    />
  );
};

const Location = () => {
  const isMobile = useMatchMedia(breakpoint.TABLET_OR_SMALLER);

  const Header = () => {
    return (
      <LocationHeader>
        {!isMobile && <HeaderSpacer />}
        <HeaderTitle>
          <Title>{isMobile ? gymSearchHeaderText.title : mobileGymSearchHeaderText.title}</Title>
          <SubTitle>{isMobile ? gymSearchHeaderText.subtitle : mobileGymSearchHeaderText.subtitle}</SubTitle>
        </HeaderTitle>
      </LocationHeader>
    );
  };

  return isMobile ? (
    <MobileLocation
      LocationHeader={Header}
      SearchForm={SearchForm}
      SearchMap={GymSearchMap}
      SearchResults={SearchResults}
    />
  ) : (
    <DesktopLocation
      LocationHeader={Header}
      LocationSidebar={LocationSidebar}
      SearchMap={GymSearchMap}
      SearchResults={SearchResults}
    />
  );
};

const LocationHeader = styled.header`
  display: flex;
  height: 120px;
  padding-top: 30px;

  @media ${breakpoint.TABLET_OR_SMALLER} {
    display: block;
    height: auto;
    padding: 20px;
  }
`;

const HeaderSpacer = styled.div`
  margin-right: 16px;

  @media ${breakpoint.TABLET_OR_SMALLER} {
    margin-right: 20px;
  }
`;

const HeaderTitle = styled.div`
  width: 75%;

  @media ${breakpoint.TABLET_OR_SMALLER} {
    width: 100%;
  }
`;

const Title = styled.h1`
  color: ${UHCLinkV2};
  font-family: Georgia, Times, 'Times New Roman', serif;
  font-size: 36px;
  font-weight: 600;
  margin: 0;

  @media ${breakpoint.TABLET_OR_SMALLER} {
    font-size: 30px;
    margin: 0;
  }
`;

const SubTitle = styled.p`
  color: ${UHCLinkV2};
  font-size: 17px;
  font-weight: normal;
  margin: 0;
  padding-top: 10px;
`;

export default page(Location, 'Location');
