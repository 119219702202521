import React from 'react';
import styled from 'styled-components/macro';
import { page } from '@rally/renew-active/hoc';
import { breakpoint } from '@rally/rewards-util';
import { UHCLink } from '@rally/rewards-view/color';

const Privacy = () => {
  return (
    <Container>
      <h2>Online Services Privacy Policy</h2>
      <p>
        We recognize that the privacy of your information is important. This Online Services Privacy Policy ("Privacy
        Policy" or "Policy") describes our practices in connection with information we collect through the online and
        mobile websites, platforms, services, and applications that we own or operate and that contain a link to this
        Privacy Policy (collectively, "Online Services").
      </p>
      <p>
        As used in this Policy, terms such as "we," "us," "our," and "Company" refer to current and future affiliated
        entities, subsidiaries, agents, contractors, or vendors of UnitedHealthcare. Our Online Services are intended
        for a United States audience. Any information you provide, including any personal information, will be
        transferred to and processed by a computer server located within the United States.
      </p>
      <p>
        By using the Online Services, you consent to our collection, use, disclosure, and storage of information as
        described in this Privacy Policy.
        <br />
        <br />
        Our Privacy Policy explains:
        <ul>
          <li>
            <a href="#ppa">When This Privacy Policy Applies</a>
          </li>
          <br />
          <li>
            <a href="#collectedInfo">What Information We Collect About You</a>
          </li>
          <br />

          <li>
            <a href="#usingInfo">How We Use Your Information</a>
          </li>
          <br />
          <li>
            <a href="#sharingInfo"> How We Share Your Information</a>
          </li>
          <br />
          <li>
            <a href="#managingInfo"> How to Manage Your Information</a>
          </li>
          <br />
          <li>
            <a href="#yourInfo">Protecting Your Information</a>
          </li>
          <br />
          <li>
            <a href="#underThirteen">Children Under 13</a>
          </li>
          <br />
          <li>
            <a href="#caliRights">Additional Rights for California Residents</a>
          </li>
          <br />
          <li>
            <a href="#healthFinance">How to Learn about Health and Financial Privacy Practices</a>
          </li>
          <br />
          <li>
            <a href="#privacyNotices">Specific Product Privacy Notices</a>
          </li>
          <br />
          <li>
            <a href="#mediaDisclaimer">Social Media Disclaimer</a>
          </li>
          <br />
          <li>
            <a href="#ssnProtection">Social Security Number Protection Policy</a>
          </li>
          <br />
          <li>
            <a href="#policyChanges">Changes to This Policy</a>
          </li>
          <br />
          <li>
            <a href="#contactUs">Contact Us</a>
          </li>
        </ul>
      </p>
      <Anchor id="ppa" />
      <h2>When This Privacy Policy Applies</h2>
      <p>
        Our Policy applies to Online Services that we own or operate and that contain a link to this Privacy Policy. Our
        Policy does not apply to information collected through other means such as by telephone, via Online Services
        that do not link to this Privacy Policy, or in person, although that information may be protected by other
        privacy policies.
      </p>
      <p>
        Our Policy does not apply to the practices of other companies or other websites or software applications that
        may be linked from or made available through our Online Services.
      </p>
      <p>
        The inclusion of a link on our Online Services or the ability to utilize a third-party website or software
        application through our Online Services does not imply that we endorse, or otherwise monitor the privacy or
        security practices of that third-party website or software application or the accuracy of its content, and your
        use of the third-party website or software application is governed by the third-party's privacy policy.
      </p>
      <p>
        Some of our products and services are regulated by certain state and federal laws, including the Health
        Insurance Portability and Accountability Act ("HIPAA") and the Gramm-Leach-Bliley Act and may have specific
        privacy practices ("Product Privacy Notices") such as a HIPAA Notice of Privacy Practices or other specific
        privacy practices that we want you to be aware. This information can be found in the{' '}
        <a href="#privacyNotices">Specific Product Privacy Notices</a> section of this Privacy Policy. When a Product
        Privacy Notice exists, the content of the Product Privacy Notice applies to your use of the product and service.
      </p>
      <p>
        This Privacy Policy is not intended to and does not create any contractual or other legal rights in or on behalf
        of any party.
      </p>
      <Anchor id="collectedInfo" />
      <h2>What Information We Collect About You</h2>
      <p>
        We may collect two basic types of information through the Online Services: (1) information you provide directly
        to us, and (2) information that is automatically provided to us or collected through your use of our Online
        Services (collectively, “Information”).
      </p>
      <p>
        Such Information may include information that identifies you such as your full name, telephone number, email
        address, postal address, certain account numbers, your User ID that you create or is assigned to you, health and
        medical information, including Protected Health Information, financial information and other information
        described in this Privacy Policy.
      </p>
      <p>
        When you use the Online Services, you may provide certain Information directly to us. For example, you may input
        a telephone number or email address into a webform or enter information into a chat functionality. We also may
        make certain information available to you on the Online Services that we collect from other sources. For
        example, we may pre-populate certain demographic information in an online form or make health or medical
        information viewable on the Online Services that you have provided to us on paper forms or maintained in other
        information systems.
      </p>
      <p>
        We also may obtain Information that is automatically collected through the Online Services. The automatically
        collected Information may include demographic, de-identified, aggregated, or certain information from your
        device such as technical information about your device, web-browser information, and server log files collected
        by us or provided by you. See <a href="#tracking">Cookies and Tracking</a> for more information on automatically
        collected information.
      </p>
      <p>
        You may limit the Information you provide or make available to us if you want to; however, that may limit your
        ability to access or use certain functions of the Online Services or to request certain services or information.
      </p>
      <h2>Mobile Devices and Applications</h2>
      <p>
        In addition to the Information identified above, we may collect the following Information:
        <br />
        <br />
        <strong>Information that Identifies You</strong>
        <ul>
          <li>Health, medical, therapy, or financial information;</li>
          <li>Information provided by UnitedHealthcare;</li>
          <li>Location data such as GPS, Wi-Fi, or carrier network location (see below for more details); and</li>
          <li>
            User files stored on your device like calendars, photos, and videos if you grant permission through your
            device settings.{' '}
          </li>
        </ul>
        <strong>What You Do on Your Device</strong>
        <ul>
          <li>
            Camera use. Certain features may have access to your camera if you grant permission in your device settings;
          </li>
          <li>Local storage;</li>
          <li>Phone dialer;</li>
          <li>Use of screen, e.g., what points are touched, frequency, etc.; and</li>
          <li>Patterns of app usage.</li>
        </ul>
        <br />
        <strong>Device or System Data</strong>
        <ul>
          <li>Mobile Device Identifier, e.g., UDID, Android ID; and</li>
          <li>
            Technical information about your device and system and application software, e.g., type of phone, Operating
            System (OS), and IP address.
          </li>
        </ul>
        <br />
        <strong>Activity, Fitness and Health Information Data</strong>
        <ul>
          <li>Steps and Other Physical Activity Data (e.g., swimming, biking, burned calories, heart rate); and</li>
          <li>Activity and Fitness Time Data;</li>
        </ul>
      </p>
      <p>
        We may obtain location data from your device to provide location-related services (e.g., driving directions or
        distance calculation, via the mobile application). You may withdraw consent to use precise, real-time, or
        network location data at any time by turning off the location-based feature on your mobile device or by not
        using any location-based features. If you withdraw your consent, functionality associated with precise,
        real-time, or network location (e.g., navigation) will no longer work.
      </p>
      <Anchor id="usingInfo" />
      <h2>How We Use Your Information</h2>
      <p>
        We may use your Information:
        <br />
        <ul>
          <li>To respond to an email, chat or other instant message or particular request from you;</li>
          <li>To communicate with you;</li>
          <li>
            To provide you with content, services, and functionality through our Online Services or other services that
            we may offer;
          </li>
          <li>To process an application for a product or service as requested by you;</li>
          <li>To authenticate you on any portion of our Online Services and with vendors acting on our behalf;</li>
          <li>To administer surveys and promotions;</li>
          <li>To personalize your experience on our Online Services;</li>
          <li>
            To provide you with informational or promotional offers, as permitted by law, that we believe may be useful
            to you, such as information about products or services provided by us or other businesses;
          </li>
          <li>To perform analytics and to improve our products, Online Services, and advertising;</li>
          <li>To facilitate the provision of software updates and product support;</li>
          <li>
            To improve products and other services related to the Online Services or to provide services or technologies
            to you;
          </li>
          <li>To comply with applicable laws, regulations, and legal process;</li>
          <li>To protect someone's health, safety, or welfare;</li>
          <li>
            To protect our rights, the rights of affiliates or related third parties, or to take appropriate legal
            action, such as to enforce our Terms of Use;
          </li>
          <li>To keep a record of our transactions and communications; and</li>
          <li>
            As otherwise necessary or useful for us to conduct our business, so long as such use is permitted by law or
            for any other purpose with your consent.
          </li>
        </ul>
      </p>
      <p>
        We may use Information to contact you through any contact information you provide through our Online Services,
        including any email address, telephone number, cell phone number, or fax number. We may communicate,
        electronically or via telephone with you about your benefit plan, programs, products, or services that are or
        may be available to you in connection with your transactions with us including, but not limited to, Online
        Services updates, account information, general wellness, prescription or appointment reminders, general health
        information, newsletters, and surveys. These electronic communications may contain protected health information.
        You acknowledge and accept that such communications may be sent unencrypted and there is some risk of disclosure
        or interception of the contents of these communications. To learn more about our communications practices,
        please visit our{' '}
        <a href="https://www.uhc.com/legal/consumer-communications-notice" rel="noopener noreferrer" target="_blank">
          Consumer Communications Notice.
        </a>{' '}
      </p>
      <p>
        We may, when permitted, combine your Information with other information, whether online or offline, maintained
        or available to us from you or from other sources, such as from our vendors, and we may use and disclose
        combined data for the purpose described in this Section or for internal business purposes. We may, when
        permitted, use and disclose de-identified and aggregated data for any purpose, which may include, without
        limitation, disclosures to third parties for analytics purposes such as evaluating the Online Services and
        providing additional benefits, programs, and services.
      </p>
      <Anchor id="sharingInfo" />
      <h2>How We Share Your Information</h2>
      <p>
        We will only share your Information with third parties as outlined in this Policy and as otherwise permitted by
        law or as permitted with your consent.
      </p>
      <p>
        We may share Information if all or part of the Company is sold, merged, dissolved, acquired, or disbanded to any
        extent in a similar transaction, or in connection with steps that may need to be taken in anticipation of such
        events.
      </p>
      <p>
        We may share Information in response to a court order, subpoena, search warrant, or to comply with law or
        regulation. We may cooperate with law enforcement authorities in investigating and prosecuting activities that
        are illegal, violate our rules, or may be harmful to other visitors.
      </p>
      <p>
        We may also share Information within the Company, including among affiliates, or with our parent company, or
        subsidiaries.
      </p>
      <p>
        We may also share Information with other third-party companies with which we have a business relationship or
        hire to perform services on our behalf. For example, we may hire a third-party company to help us send and
        manage email, and we might provide that third-party company with your email address and certain other
        Information for them to send you an email message on our behalf. Similarly, we may hire third-party companies to
        host or operate some of our Online Services and related computers and software applications.
      </p>
      <Anchor id="managingInfo" />
      <h2>How to Manage Your Information</h2>
      <p>
        Our Online Services may permit you to view your profile, if applicable, and access related Information about you
        and to request changes to such Information. Please remember, however, if we have already disclosed some of this
        Information to third parties, we may not have access to that disclosed information and may not be able to force
        the modification of any Information by the third parties to whom we have made those disclosures.
      </p>
      <h2>Communication Preference Management</h2>
      <p>
        Our Online Services may permit you to select how you would like to receive certain communications. You can
        update your communication preferences by calling the toll-free member phone number on your health plan or member
        ID or you may stop receiving emails by unsubscribing via a link provided in our email communications.
      </p>
      <p>
        We may offer mobile applications that enable us to communicate with you through push notifications. Where mobile
        applications are offered, you may be able to manage push notifications in your mobile phone or tablet settings.
        You may also be able to control preview settings in your email applications.
      </p>
      <p>
        If you need additional assistance in opting out of a communication, please <a href="#contactUs">Contact Us</a>{' '}
        for assistance. Please be aware that opt-outs may not apply to certain types of communications, such as account
        status, Online Service updates, or other communications.
      </p>
      <h2>Informational Electronic Communications</h2>
      <p>
        We may provide you with Informational Electronic Communications. Informational Electronic Communications
        include, but are not limited to, information about your benefit plan, programs, products, or services that are
        or may be available to you in connection with your transactions with us, Online Services updates, account
        information, general wellness prescription or appointment reminders, general health information, newsletters,
        and surveys.
      </p>
      <p>
        Any request to opt-out of receiving Informational Electronic Communications will be effective only after we have
        a reasonable period of time to process your request. Opt-outs may not apply to certain types of communications,
        such as account status, Online Service updates, or other communications.
      </p>
      <p>
        Communications sent electronically may be provided either (1) via email; (2) by access to a website that we will
        designate in a notice we send to you when the information is available; or (3) by other electronic means. At
        times, in our sole discretion, we may still send you paper communications in lieu of, or in addition to, sending
        them electronically.
      </p>
      <h2>Informational Calls and Texts</h2>
      <p>
        When you provide us a telephone number, whether landline or mobile, we may contact you, using automated, pre-
        recorded, or non-automated means, to provide you information about existing benefits, programs, products,
        services, or tools.
      </p>
      <p>
        Personal Information collected through our texting programs will not be shared, sold, or disclosed to third
        parties for their own marketing purposes.
      </p>
      <p>
        We reserve the right to modify this section at any time. The modified section will be effective immediately upon
        posting. Your continued receipt of Informational Electronic Communications and Informational Calls and Texts
        will constitute your acceptance of the modified section.
      </p>
      <Anchor id="tracking" />
      <h2>Cookies and Tracking</h2>
      <p>
        The Company may use various technologies, including cookies, tokens, tags, web logs, web beacons, scripts, and
        web server logs to gather automatically collected information and may aggregate this information from visitors
        of our Online Services or to enable certain features of our Online Services. This information may include
        demographic data, technical information about the technology (e.g., phone, computer) you use to connect to the
        Online Services, web browser information, your IP address, and browsing behavior such as pages visited and how
        often they are visited (collectively, "Activity Information"). We may also use third-party analytics companies
        to provide these services.
      </p>
      <p>
        We may also allow third parties to use cookies and other technologies to collect Activity Information and to
        track browsing activity over time and across third-party websites such as web browsers used to read our Online
        Services, which websites are referring traffic or linking to our Online Services, and to deliver targeted
        advertisements to you. We do not control these third-party technologies, and their use of such technologies is
        governed by their own privacy policies. For more information about third-party advertising networks and similar
        entities that use these technologies, see{' '}
        <a href="https://youradchoices.com/control" rel="noopener noreferrer" target="_blank">
          youradchoices.com/control,
        </a>{' '}
        and to opt-out of such ad networks' and services' advertising practices, go to{' '}
        <a href="https://optout.aboutads.info/" rel="noopener noreferrer" target="_blank">
          optout.aboutads.info
        </a>{' '}
        and{' '}
        <a href="https://optout.networkadvertising.org/" rel="noopener noreferrer" target="_blank">
          optout.networkadvertising.org/.
        </a>{' '}
        Once you click the links, you may choose to opt out of such advertising from all participating advertising
        companies or only from advertising provided by specific advertising companies. Please note that, to the extent
        advertising technology is integrated into the Online Services, you may still receive advertisements even if you
        opt out of tailored advertising. In that case, the ads will just not be tailored. Also, we do not control any of
        the above opt-out links and are not responsible for any choices you make using these mechanisms or the continued
        availability or accuracy of these mechanisms.
      </p>
      <p>
        Activity Information is captured using various technologies and may include cookies. "Cookies" are small text
        files that may be placed on your computer or mobile device when you visit an Online Service or click on a URL
        using your web browser. Cookies may include "single-session cookies" which generally record information during
        only a single visit to a website and then are erased, and "persistent" cookies which are generally stored on a
        computer or mobile device unless or until they are deleted or are set to expire. You may disable cookies and
        similar items by adjusting your browser preferences at any time; however, this may limit your ability to take
        advantage of all the features on our Online Services. In addition, you may also have additional means to manage
        the collection of Activity Information by:
        <br />
        <ul>
          <li>
            Managing the use of "flash" technologies, with the{' '}
            <a
              href="https://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager.html"
              rel="noopener noreferrer"
              target="_blank"
            >
              Flash management tools
            </a>{' '}
            available at Adobe's website;
          </li>
          <li>Visiting the Adobe Digital Marketing Suite to "Opt-Out" of data aggregation and analysis;</li>
          <li>Clicking on the "Opt-Out" link at the bottom of the home web page, if applicable; and</li>
          <li>
            Visiting{' '}
            <a href="https://www.google.com/settings/ads" rel="noopener noreferrer" target="_blank">
              Google
            </a>{' '}
            to "Opt-Out" of display advertising or customize Google display network ads.
          </li>
        </ul>
      </p>
      <p>
        Please note that we do not currently respond to web browser "Do Not Track" signals that provide a method to opt
        out of the collection of Information about online activities over time and across third-party websites or online
        services because, among other reasons, there is no common definition of such signals and no industry-accepted
        standards for how such signals should be interpreted.
      </p>
      <p>
        We gather Activity Information about you to improve the quality of our services, such as the best method and
        time to contact you. Without limiting the other ways in which we may use Information as described herein, we may
        otherwise use and disclose your Activity Information unless restricted by this Policy or by law. Some examples
        of the ways we use your Activity Information include:
        <br />
        <ul>
          <li>Customizing your experiences, including managing and recording your preferences;</li>
          <br />
          <li>Authenticating your account information;</li>
          <br />
          <li>Marketing, product development, and research purposes;</li>
          <br />
          <li>Tracking resources and data accessed on the Online Services;</li>
          <br />
          <li>Developing reports regarding Online Service usage, activity, and statistics;</li>
          <br />
          <li>Assisting users experiencing problems with our services;</li>
          <br />
          <li>Updating and servicing our Online Services;</li>
          <br />
          <li>Enabling certain functions and tools on the Online Services; and</li>
          <br />
          <li>Tracking paths of visitors to the Online Services and within the Online Services.</li>
        </ul>
      </p>
      <p>
        As described above, we may use tracking technologies that allow us to recognize your device when you return to
        our Online Services within a period of time, as determined by us, and to support automatic login to your Online
        Services. To maintain your privacy, you should affirmatively log out of your account prior to your session
        ending (whether you end your session or we end your session, for example if our Online Services has "timed out"
        - i.e., we have ended your session automatically after a period of inactivity as determined by us in our sole
        discretion). Unless you affirmatively log out of your account, you may be automatically logged back in the next
        time you or any user of your devices visits the Online Services.
      </p>
      <h2>Retention of Data</h2>
      <p>
        We will retain your personally identifiable information (PII) and Activity Information for as long as is
        necessary for the purposes set out in this Privacy Policy. We will retain and use your PII to the extent
        necessary to comply with our legal obligations (for example, if we are required to retain your data to comply
        with applicable laws), resolve disputes, and enforce our legal agreements and policies.
      </p>
      <h2>Posting Messages, Comments and Content</h2>
      <p>
        Our Online Services may have voluntary collaboration areas, including but not limited to "blogs," "bulletin
        boards," "leader boards," and "health games," that permit users to have collaborative discussions and/or share
        Information. Some of our Online Services may permit you to select a display name or image that will be your
        "nickname" on the Online Service. Please note, any Information you submit or post to these collaboration areas,
        including your display name or image, may be visible by other users of the Online Service, and such users may be
        able to identify you and make information public. Out of respect for the privacy of others, please avoid
        referring to the full names of others in your response and refrain from sharing other participants’ identities
        or comments outside of these discussions.
      </p>
      <p>
        Also see our <a href="#mediaDisclaimer">Social Media Disclaimer</a> to learn about our practices related to
        posting on our social media accounts.
      </p>
      <Anchor id="yourInfo" />
      <h2>Protecting Your Information </h2>
      <p>
        We maintain administrative, technical, and physical safeguards designed to protect the Information that you
        provide on our Online Services. These safeguards vary based on the sensitivity of the Information that is being
        collected, used, and stored. We cannot guarantee the security of our Online Services, nor can we guarantee the
        security of the Information you transmit to us over the Internet, including your use of email. We are not liable
        for the illegal acts of third parties such as criminal hackers.
      </p>
      <p>
        It is your responsibility to safeguard the devices you use to access our Online Services (such as laptops,
        tablets and mobile devices), and to use appropriate security settings on those devices. If those devices are
        lost, stolen or misplaced, others may be able to access your account and your personal Information using those
        devices. You should affirmatively log out of your account (i) prior to ending your session, or (ii) if you will
        be inactive on the Online Services for more than a few minutes; otherwise, the next user of that computer or
        device, particularly a public one or one not owned by you, may be able to access your account and the
        Information in your account if your session has not ended.
      </p>
      <p>
        You agree that we are not responsible for any harm that may result from someone accessing your account or
        personal Information on any computer or device where you do not, for any reason, take the necessary steps to log
        out of your account prior to ending a session on such device or computer.
      </p>
      <p>
        We retain Information for as long as necessary for the purpose for which it is collected, subject to a longer
        period if the Information is relevant to a legal challenge.
      </p>
      <Anchor id="underThirteen" />
      <h2>Children Under 13</h2>
      <p>
        We will not intentionally collect any personal information (as that term is defined in the Children's Online
        Privacy Protection Act) from children under the age of 13 through our Online Services without receiving parental
        consent. If you think that we have collected such personal information from a child under the age of 13 through
        our Online Services, please <a href="#contactUs">Contact Us</a> immediately.
      </p>
      <Anchor id="caliRights" />
      <h2>Additional Rights for California Residents</h2>
      <h2>California “Shine the Light” Privacy Rights</h2>
      <p>
        California law permits our customers who are California residents to request certain information regarding the
        disclosure of certain personal information to third parties for their direct marketing purposes.
      </p>
      <p>
        If we have disclosed any personal information to third parties for direct marketing purposes, we will provide a
        list of the categories of personal information, along with the names and addresses of these third parties to you
        at your request. To make such a request, write us at the postal or email address found in the{' '}
        <a href="#contactUs">Contact Us</a> section of this Policy.
      </p>
      <p>
        This request may be made no more than once per calendar year. We reserve our right not to respond to requests
        submitted other than to the specified email or postal address. You should put "California Privacy Rights-Direct
        Marketing" in the email subject line and in the body of your request. You must provide us with specific
        information regarding yourself so that we can accurately respond to the request.
      </p>
      <h2>California Minors Under 18</h2>
      <p>
        If you are a California resident under the age of 18 and are a registered user of our Online Services, you may
        request that we remove from our Online Services any content you post to our Online Services that can be accessed
        by any other user (whether registered or not). Please note that any content that is removed from visibility on
        our Online Services may still remain on our servers and in our systems. To request removal of content under this
        provision, please write or email us at the postal or email address found in the{' '}
        <a href="#contactUs">Contact Us</a> section of this Policy. When you write to us, please provide us with a
        description of the content and the location of the content on our Online Services, along with any other
        information that we may require to consider your request. Please note that removal of content under this
        provision does not ensure complete or comprehensive removal of the content or information posted on the Online
        Services by you.
      </p>
      <Anchor id="healthFinance" />
      <h2>How to Learn about Health and Financial Privacy Practices</h2>
      <p>
        Some of our products and services are regulated by certain laws, including the Health Insurance Portability and
        Accountability Act (“HIPAA”) and the Gramm-Leach-Bliley Act.
      </p>
      <h2>Health Information Privacy Practices</h2>
      <p>
        If you receive healthcare products or services, visit the applicable HIPAA Notice of Privacy Practices,
        available in the <a href="#privacyNotices">Specific Product Privacy Notices</a> section of this Policy, to learn
        about how we protect, use, and share Protected Health Information (PHI) for your medical treatment, payment for
        services, our operations, or as permitted by law. The HIPAA Notice of Privacy Practices also describes your
        right to:
      </p>
      <ul>
        <li>
          See and obtain a copy of certain PHI, including your medical, billing, claims, and case management records;
        </li>
        <li>Request confidential communications;</li>
        <li>Request certain restrictions on the use and disclosure of your PHI;</li>
        <li>Request a correction to your PHI; and</li>
        <li>Request a paper copy of the HIPAA Notice of Privacy Practices. </li>
      </ul>
      <p>Please note, we do not use PHI for activities or secondary purposes not permitted by HIPAA.</p>
      <p>
        You may notify us of a personal representative permitted to act on your behalf by contacting the toll-free
        member phone number on your health plan ID or other membership card.
      </p>

      <h2>Financial Information Privacy Practices</h2>
      <p>
        If you receive financial products or services, visit the applicable Financial Information Privacy Notice,
        available in the <a href="#privacyNotices">Specific Product Privacy Notices</a> section of this Policy, to learn
        about how we protect, use, and share your personal information, and how you can contact us to ask questions
        about that activity.
      </p>
      <Anchor id="privacyNotices" />
      <h2>Specific Product Privacy Notices</h2>
      <p>
        The following Product Privacy Notices supplement this Policy and explain specific privacy notices with respect
        to certain products and services that you may use, including access to more information about health or
        financial privacy notices.
        <ul>
          <li>
            <a
              href="https://www.uhc.com/content/dam/uhcdotcom/en/npp/NPP-UHC-MR-AMP-UMS-EN.pdf"
              rel="noopener noreferrer"
              target="_blank"
            >
              HIIPAA Notice of Privacy Practices – Medical for AARP Medicare Plans and UnitedHealthcare Medicare Plans
            </a>
          </li>
        </ul>
      </p>
      <Anchor id="mediaDisclaimer" />
      <h2>Social Media Disclaimer</h2>
      <p>
        Are you using social media? So are we! We encourage you to read, share, follow us and provide commentary on
        Facebook®, Twitter®, Pinterest®, Instagram® and other social media sites ("Social Media Site"). Before you post
        on our pages or accounts, please make sure you read our social media disclaimer:
      </p>
      <p>
        We are under no obligation to screen or monitor your posts or any other user content; however, we reserve the
        right to monitor participation to ensure that you stay on topic, are courteous and avoid making offensive
        comments. Your posts and user content must adhere to the following requirements and cannot:
        <br />
        <ul>
          <li>
            Contain any third-party material including logos, drawings, tattoos, photographs, pictures, sculptures,
            paintings and other images or works of art, phrases, trademarks, trade secrets, or other items without
            explicit, prior, written permission to use such materials;
          </li>
          <li>
            Contain sexually explicit, graphic, gratuitous or unnecessarily violent content or defamatory or derogatory
            content against any ethnic, racial, gender, religious, sexual orientation, professional or age group, or
            contain any pornographic or nude material;
          </li>
          <li>
            Contain any private information about yourself or any other individual, including without limitation,
            information related to the health of the individual, financial information about the individual, or any
            identification or account numbers related to the individual, with or without their permission or consent;
          </li>
          <li>
            Contain any software viruses or any other computer code, files or programs designed to interrupt, destroy or
            limit the functionality of any computer software, hardware, or telecommunications equipment; and
          </li>
          <li>
            Contain any advertising, promotional materials, "junk mail," "spam," "chain letters," "pyramid schemes," or
            promote illegal activity and/or illegal contests, sweepstakes, gambling, including any online casino, sports
            books, bingo, poker, or any other form of solicitation.
          </li>
        </ul>
      </p>
      <p>
        We reserve the right to edit comments for content, remove off-topic contributions, delete offensive comments or
        remarks, block offensive contributors, and delete actual or suspected spam content from any Social Media Site.
        Please be aware that once you post something online, there is the potential for numerous individuals to read
        your words, even years from now. Therefore, we suggest that you exercise caution when posting on any Social
        Media Sites and that you not disclose Information like your location, medical record number, personal medical
        information, financial information, etc. We are not responsible for the content of any comments or responses
        posted by others to any Online Service or Social Media Site we manage or monitor. We do not control the
        placement of any marketing or advertising displayed on our pages by social media or third-party organizations.
      </p>
      <p>
        Please remember that content posted on any of our social media profiles or platforms is for general
        informational purposes only and should not be considered medical advice and should not replace a consultation
        with your health care or financial professional. Always consult an appropriate health care or financial
        professional for your specific needs. If you are experiencing a medical emergency, call 9-1-1 or your local
        emergency number. Some treatments mentioned on social media formats may not be covered by your health plan.
        Please refer to your benefit plan documents for information about coverage.
      </p>
      <p>
        We reserve the right to respond to any post or user content and may occasionally privately request your contact
        information to assist you offline, with your consent, by routing the matter to the appropriate persons or
        department for further handling. Any further questions about your account, claims or benefits or request for
        additional information can be addressed by visiting your member or patient website or calling the toll-free
        member phone number on your health plan or member ID.
      </p>
      <p>All trademarks are the property of their respective owners.</p>
      <Anchor id="ssnProtection" />
      <h2>Social Security Number Protection Policy</h2>
      <p>
        It is our policy to protect the confidentiality of Social Security numbers (“SSNs”) that we receive or collect
        in the course of business. We secure the confidentiality of SSNs through various means, including physical,
        technical, and administrative safeguards that are designed to protect against unauthorized access. It is our
        policy to limit access to SSNs to that which is lawful and to prohibit unlawful disclosure of SSNs.
      </p>
      <Anchor id="policyChanges" />
      <h2>Changes to This Policy</h2>
      <p>
        We may change this Policy at any time. If we do so, such change will appear on this page. You may always visit
        this Policy to learn of any updates. Your continued access to or use of the Online Services constitutes your
        consent to these changes to this Policy.
      </p>
      <Anchor id="contactUs" />
      <h2>Contact Us</h2>
      <p>
        Contact us regarding this Policy or related privacy practices. If you believe we or any company associated with
        us has misused your Information, please contact us immediately.
        <br />
        <br />
        <address>
          UnitedHealthcare Privacy Office
          <br />
          PO Box 1459
          <br />
          Minneapolis, MN 55440
        </address>
      </p>
      <h2>Effective Date</h2>
      <p>October 4, 2023</p>
      <br />
    </Container>
  );
};

const Container = styled.article`
  margin: 40px;

  section {
    margin: 40px 0;
  }

  a {
    color: ${UHCLink};
    cursor: pointer;
    text-decoration: underline !important;
  }

  @media ${breakpoint.MOBILE} {
    margin: 20px;
  }
`;

const Anchor = styled.span`
  display: block;
  height: 183px;
  margin-top: -183px;
  visibility: hidden;

  @media ${breakpoint.TABLET_OR_SMALLER} {
    height: 133px;
    margin-top: -133px;
  }
`;
export default page(Privacy, 'Privacy');
