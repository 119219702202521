export default {
  Home: '/home',
  FitnessFeatures: '/benefits',
  Brain: '/brain',
  Classes: '/classes',
  Fitbit: '/fitbit',
  GymSearch: '/location',
  Nominate: '/nominate',
  NotFound: '/not-found',
  Privacy: '/privacy',
  Terms: '/terms',
};
