import React from 'react';
import styled from 'styled-components/macro';
import { BlueRibbonV2, LightBlueRibbonV2 } from '@rally/rewards-view/color';
import { useResponsive } from '@rally/rewards-view/hook';
import { ButtonLink, ContentRow } from '@rally/rewards-view/component';
import { breakpoint } from '@rally/rewards-util';
import { page } from '@rally/renew-active/hoc';
import { Adobe, config } from '@rally/renew-active/constant';
import { FindFitnessLocationSearch } from '@rally/renew-active/component';
import { findPlanLink } from '@rally/renew-active/util';
import { Triangle } from '../Components';
import { DescriptionImage, PageTitle, TriangleContainer } from './shared';
import PagePath from '../constants/PagePath';
import {
  bottomContentV2,
  pageSubtitleV2,
  pageTitleV2,
  searchLabel,
  searchText,
  stayConnectedSectionV2,
  stayFitSectionV2,
  stayFocusedSectionV2,
  staySharpSectionV2,
} from '../constants/homeText';
import stayFocusedV2 from '../assets/home/stayFocusedV2Merged.jpg';
import stayFocusedMobileV2 from '../assets/home/stayFocusedMobileV2.jpg';
import dumbellsImgV2 from '../assets/home/dumbbellsV2.jpg';
import ipadV2 from '../assets/home/staySharpV2.jpg';
import ipad2023Update from '../assets/home/staySharpV2023Update.png';
import heroWomanV2Merged from '../assets/home/heroWomanV2Merged.jpg';
import heroWomanMobileV2 from '../assets/home/heroWomanMobileV2.jpg';
import heroWomanTabletV2 from '../assets/home/heroWomanTabletV2.jpg';
import stayConnectedV2 from '../assets/home/stayConnectedV2Merged.jpg';
import stayConnectedMobileV2 from '../assets/home/stayConnectedMobileV2.jpg';
import fitbitLogoV2 from '../assets/home/fitbitLogoV2.png';
import aarpLogo from '../assets/home/AARPLogo.svg';

type StayConnectedSectionV2Key = keyof typeof stayConnectedSectionV2;
type StayFitSectionV2Key = keyof typeof stayFitSectionV2;

const Home = () => {
  const { resp, isMobile } = useResponsive();
  const renewActive2023CopyUpdates = config.renewActive2023CopyUpdates;
  const textSuffix = renewActive2023CopyUpdates ? '2023Update' : '';

  const desktopSearchGymSearch = (
    <GymSearch>
      <HeaderParagraph>{searchText}</HeaderParagraph>
      <FindFitnessLocationSearch
        label={searchLabel}
        linkName={Adobe.homeFindLocation}
        locationPath={PagePath.GymSearch}
      />
    </GymSearch>
  );

  const desktopSearchRowItems = [desktopSearchGymSearch];

  const desktopTop = (
    <>
      <TopRow>
        <TopContentRow>
          <PageDescription>
            <HomeTitle text={pageTitleV2} />
            <PageSubtitle>{pageSubtitleV2}</PageSubtitle>
          </PageDescription>
          <></>
        </TopContentRow>
        <SearchContentRow>{desktopSearchRowItems.map((el) => el)}</SearchContentRow>
      </TopRow>
    </>
  );

  const ipadV2Img = <IpadImage src={ipadV2} width={resp('500px', '570px', '100%')} />;

  const ipad2023UpdateImg = <IpadImage2023Update src={ipad2023Update} width={resp('500px', '570px', '100%')} />;

  const mobileTop = (
    <>
      <TopRow>
        <TopContentRow>
          <PageDescription>
            <HomeTitle text={pageTitleV2} />
            <PageSubtitle>{pageSubtitleV2}</PageSubtitle>
          </PageDescription>
        </TopContentRow>
      </TopRow>
      <SearchContentRow>
        <GymSearch>
          <HeaderParagraph>{searchText}</HeaderParagraph>
          <FindFitnessLocationSearch
            label={searchLabel}
            linkName={Adobe.homeFindLocation}
            locationPath={PagePath.GymSearch}
          />
        </GymSearch>
      </SearchContentRow>
      <TriangleContainer z={4}>
        <Triangle color={LightBlueRibbonV2} dir="left" orientation="bottom" />
      </TriangleContainer>
    </>
  );

  return (
    <Container>
      {isMobile ? mobileTop : desktopTop}
      <StayFitContentRow>
        <DumbellImg src={dumbellsImgV2} width={resp('299px', '350px', 'calc(100px + 25%)')} />
        <Section>
          <SectionTitle>{stayFitSectionV2.title}</SectionTitle>
          <SectionText>{stayFitSectionV2[`content${textSuffix}` as StayFitSectionV2Key]}</SectionText>
          <SectionCta>
            <ButtonLink data-testid="searchNowBtn" linkName={Adobe.stayFit} theme="darkV2" to={PagePath.GymSearch}>
              {stayFitSectionV2.cta}
            </ButtonLink>
          </SectionCta>
        </Section>
      </StayFitContentRow>
      <StayFocusedContentRow>
        <Section style={{ marginTop: '-20px', width: '100%' }}>
          <SectionTitle>{stayFocusedSectionV2.title}</SectionTitle>
          <SectionText>{stayFocusedSectionV2.content}</SectionText>
          <SectionCta>
            <ButtonLink
              data-testid="fitnessFeaturesBtn"
              linkName={Adobe.stayFocused}
              theme="darkV2"
              to={PagePath.FitnessFeatures}
            >
              {stayFocusedSectionV2.cta}
            </ButtonLink>
          </SectionCta>
        </Section>
        <></>
      </StayFocusedContentRow>
      <StaySharpContentRow>
        {renewActive2023CopyUpdates ? ipad2023UpdateImg : ipadV2Img}
        <Section style={{ width: '100%' }}>
          <SectionTitle>{staySharpSectionV2.title}</SectionTitle>
          <SectionText>{staySharpSectionV2.content}</SectionText>
          <SectionCta>
            <ButtonLink data-testid="trainYourBrainBtn" linkName={Adobe.staySharp} theme="darkV2" to={PagePath.Brain}>
              {staySharpSectionV2.cta}
            </ButtonLink>
          </SectionCta>
        </Section>
      </StaySharpContentRow>
      <StayConnectedContentRow>
        <Section style={{ width: '100%' }}>
          <SectionTitle>{stayConnectedSectionV2.title}</SectionTitle>
          <SectionText>{stayConnectedSectionV2[`content${textSuffix}` as StayConnectedSectionV2Key]}</SectionText>
          <SectionCta>
            <ButtonLink data-testid="classesEventsBtn" linkName={Adobe.stayConnected} theme="darkV2" to="/classes">
              {stayConnectedSectionV2[`cta${textSuffix}` as StayConnectedSectionV2Key]}
            </ButtonLink>
            <FitbitLogoV2 src={fitbitLogoV2} />
          </SectionCta>
        </Section>
        <></>
      </StayConnectedContentRow>
      <BottomContentRow>
        <Section style={{ marginTop: resp('40px', '40px', '20px') }}>
          <SectionTitle style={{ color: 'white', marginBottom: '20px', fontSize: '30px' }}>
            {bottomContentV2.content}
          </SectionTitle>
          <SectionCta>
            <ButtonLink
              data-testid="findMedicarePlanBtn"
              linkName={Adobe.findPlan}
              target="_blank"
              theme="lightV2"
              to={findPlanLink()}
            >
              {bottomContentV2.cta}
            </ButtonLink>
          </SectionCta>
        </Section>
        <AARPLogo alt="AARP Medicare Plans from UnitedHealthcare Logo" src={aarpLogo} width={'100%'} />
      </BottomContentRow>
    </Container>
  );
};

const TopRow = styled.div`
  background: white url(${heroWomanV2Merged}) no-repeat bottom center;
  background-size: auto 100%;

  @media ${breakpoint.TABLET} {
    background-image: url(${heroWomanTabletV2});
    background-position: bottom left;
  }

  @media ${breakpoint.TABLET_TO_ARCADE_HEADER_MOBILE} {
    background-position: bottom left;
  }

  @media ${breakpoint.MOBILE} {
    background-image: url(${heroWomanMobileV2});
    margin-bottom: 30px;
    padding-bottom: 250px;
  }
`;

const TopContentRow = styled(ContentRow)`
  background: transparent;
  z-index: 2;

  @media ${breakpoint.MOBILE} {
    margin: 0;
  }
`;

const SearchContentRow = styled(ContentRow)`
  background-color: transparent;
  font-weight: 300;
  padding-bottom: 50px;
  width: 50%;
  z-index: 3;

  form {
    width: 80%;
  }

  @media ${breakpoint.TABLET} {
    width: 60%;

    form {
      width: 85%;
    }
  }

  @media ${breakpoint.MOBILE} {
    width: 100%;
  }
`;

const StayFitContentRow = styled(ContentRow)`
  background-color: ${LightBlueRibbonV2};
  height: 300px;
  z-index: 5;

  @media ${breakpoint.TABLET} {
    height: 270px;
  }

  @media ${breakpoint.MOBILE} {
    height: auto;
    padding-top: 34px;
  }
`;

const StayFocusedContentRow = styled(ContentRow)`
  background: white url(${stayFocusedV2}) no-repeat top center;
  background-size: 100% auto;
  height: 444px;
  z-index: 7;

  @media ${breakpoint.TABLET} {
    background-size: auto 100%;
    height: auto;
    padding-bottom: 40px;
    padding-top: 75px;
  }

  @media ${breakpoint.TABLET_TO_ARCADE_HEADER_MOBILE} {
    background-size: 100% auto;
  }

  @media ${breakpoint.MOBILE} {
    background-image: url(${stayFocusedMobileV2});
    background-position: bottom center;
    background-size: auto 100%;
    height: 518px;
    margin: 0;
    padding-top: 34px;
    z-index: 4;
  }
`;

const StaySharpContentRow = styled(ContentRow)`
  background-color: ${LightBlueRibbonV2};
  height: 350px;
  z-index: 9;

  @media ${breakpoint.MOBILE} {
    height: auto;
  }
`;

const StayConnectedContentRow = styled(ContentRow)`
  background: white url(${stayConnectedV2}) no-repeat top center;
  background-size: 100% auto;
  height: 494px;
  z-index: 11;

  @media ${breakpoint.TABLET} {
    background-size: auto 100%;
    height: auto;
    padding-bottom: 40px;
    padding-top: 50px;
  }

  @media ${breakpoint.TABLET_TO_ARCADE_HEADER_MOBILE} {
    background-size: 100% auto;
  }

  @media ${breakpoint.MOBILE} {
    background-image: url(${stayConnectedMobileV2});
    background-position: bottom center;
    background-size: auto 100%;
    height: 659px;
    margin: 0;
    padding-top: 34px;
  }
`;

const BottomContentRow = styled(ContentRow)`
  background-color: ${BlueRibbonV2};
  height: 270px;
  z-index: 13;

  @media ${breakpoint.TABLET} {
    height: calc(300px + 10%);
  }

  @media ${breakpoint.MOBILE} {
    height: auto;
  }
`;

const DumbellImg = styled(DescriptionImage)`
  @media ${breakpoint.TABLET_OR_LARGER} {
    margin-left: 40px;
    margin-top: 10px;
  }

  @media ${breakpoint.TABLET} {
    margin-left: 20px;
    margin-top: 0;

    > div {
      align-items: center;
      display: flex;
      justify-content: center;
    }

    img {
      width: 250px;
    }
  }

  @media ${breakpoint.MOBILE} {
    width: calc(50px + 45%);

    img {
      width: 100%;
    }
  }
`;

const Container = styled.div`
  overflow: hidden;
  position: relative;
`;

const PageDescription = styled.div`
  padding: 10px 0 0 40px;

  @media ${breakpoint.MOBILE} {
    padding: 0;
  }
`;

const HomeTitle = styled(PageTitle)`
  padding-bottom: 20px;
  padding-top: 47px;
  width: 120%;

  h1 {
    color: ${BlueRibbonV2};
  }

  @media ${breakpoint.MOBILE} {
    padding-top: 24px;
    width: 100%;

    h1 {
      text-align: left;
    }
  }
`;

const PageSubtitle = styled.p`
  color: ${BlueRibbonV2};
  font-size: 17px;
  font-weight: 300;
  margin: 0;
  padding: 0;
  width: 85%;

  @media ${breakpoint.MOBILE} {
    width: 100%;
  }
`;

const HeaderParagraph = styled(PageSubtitle)`
  color: ${BlueRibbonV2};
  margin-bottom: 20px;
`;

const IpadImage = styled(DescriptionImage)`
  margin-left: 0;

  @media ${breakpoint.TABLET} {
    img {
      width: 416px;
    }
  }

  @media ${breakpoint.MOBILE} {
    margin-left: 0;
    width: 80%;
  }
`;

const GymSearch = styled.div`
  padding: 0 0 40px 40px;
  width: 100%;

  @media ${breakpoint.MOBILE} {
    padding: 0;
  }
`;

const IpadImage2023Update = styled(DescriptionImage)`
  margin-left: 0;
  img {
    display: block;
    height: 60%;
    margin: auto;
    width: 60%;
  }
`;

const Section = styled.div`
  margin: 40px;

  @media ${breakpoint.MOBILE} {
    margin: 0 0 25px 0;
  }
`;

const SectionTitle = styled.h2`
  color: ${BlueRibbonV2};
  font-size: 36px;
  font-weight: 500;
  margin: 0;

  @media ${breakpoint.MOBILE} {
    font-size: 26px;
    font-weight: 400;
    margin-top: 30px;
  }
`;

const SectionText = styled.p`
  color: ${BlueRibbonV2};
  font-weight: 300;
  margin: 10px 0 15px 0;
  padding: 0;
`;

const SectionCta = styled.div`
  display: flex;

  @media ${breakpoint.MOBILE} {
    flex-direction: column;

    a {
      width: auto;
    }
  }
`;

const FitbitLogoV2 = styled.img`
  display: inline-block;
  height: 35px;
  margin-left: 33px;
  width: 135px;

  @media ${breakpoint.MOBILE} {
    height: auto;
    margin: 20px 0;
    width: 100px;
  }
`;

const AARPLogo = styled(DescriptionImage)`
  margin: 0 auto;
  margin-top: -40px;
  width: 50%;

  @media ${breakpoint.MOBILE} {
    margin-bottom: 30px;
    margin-top: 20px;
  }
`;

export default page(Home, 'Home');
