import React from 'react';
import { Redirect, Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import { Footer, Header } from './Components';
import { Benefits, Brain, Classes, Fitbit, Home, Location, Privacy, Terms } from './Pages';
import styled from 'styled-components/macro';
import { GlobalFocus } from '@rally/rewards-view/color';
import { CONTENT_PX } from '@rally/rewards-util';
import { Adobe } from '@rally/renew-active/constant';
import { NotFound } from '@rally/renew-active/component';
import PagePath from './constants/PagePath';
import { DatadogScript } from './DatadogScript';

const Prospect = () => {
  // const isProd = window.ENVIRONMENT.ENVIRONMENT === 'prod';

  return (
    <Router>
      <Page>
        <DatadogScript />
        <section id="scrollTop" />
        <Header />
        <Body>
          <Switch>
            <Route
              exact
              path="/"
              render={() => <Redirect to={{ pathname: PagePath.Home, search: window.location.search }} />}
            />
            <Route component={Benefits} path={PagePath.FitnessFeatures} />
            <Route component={Brain} path={PagePath.Brain} />
            <Route component={Classes} path={PagePath.Classes} />
            <Route component={Fitbit} path={PagePath.Fitbit} />
            <Route component={Home} path={PagePath.Home} />
            <Route component={Location} path={PagePath.GymSearch} />
            {/* <Route
              component={Nominate}
              path={
                isProd
                  ? '/(nominate|nominate-success|nominate-failure)'
                  : '/(nominate|nominate-dev|nominate-success|nominate-failure)'
              }
            /> */}
            <Route component={Privacy} path={PagePath.Privacy} />
            <Route component={Terms} path={PagePath.Terms} />
            <Route
              path={PagePath.NotFound}
              render={() => (
                <NotFound linkName={Adobe.noTrack} theme="darkV2" to={PagePath.Home}>
                  Take Me Home
                </NotFound>
              )}
            />
            <Redirect to={PagePath.NotFound} />
          </Switch>
          <Footer />
        </Body>
      </Page>
    </Router>
  );
};

const Page = styled.section`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative;

  *:focus {
    outline: ${GlobalFocus} auto 6px !important;
  }
`;

const Body = styled.section`
  display: block;
  flex: 1 1 auto;
  height: 100%;
  margin: 0 auto;
  max-width: ${CONTENT_PX}px;
  position: relative; /* need this to position inner content */
  scroll-behavior: smooth;
  width: 100%;
`;

export default Prospect;
