const sharedSubtitle = 'Enter your ZIP Code to find a list of fitness locations near you.';
const sharedTitle = 'Find a Fitness Location';

export const gymSearchHeaderText = {
  subtitle: sharedSubtitle,
  title: `${sharedTitle} Near You`,
};

export const mobileGymSearchHeaderText = {
  subtitle: sharedSubtitle,
  title: sharedTitle,
};
