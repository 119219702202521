export const pageTitle = 'Fitness Location Features';

export const activateBody = {
  title: 'Exercise your ability to get a free gym membership at no additional cost.',
  content: [
    {
      title: 'Convenient and close to home.',
      text: 'Get a membership, take classes and work out at any participating location near you.',
    },
    {
      title: 'Access to the largest national network of gyms and fitness locations.',
      text: 'Renew Active added even more fitness locations to our extensive network, including premium gyms. Many locations even have pools.',
    },
    {
      title: 'Find the fitness location that fits your style.',
      text: 'Choose from popular local and national gyms, fitness locations and community organizations.',
    },
    {
      title: 'You have options.',
      text: 'If you prefer to work out from home, you can view thousands of on-demand workout videos and live streaming fitness classes.',
    },
  ],
};

export const didYouKnow = {
  labelTitle: 'Find a Fitness Location Near You',
  title: 'Did you know?',
  text: 'Choosing a fitness location close to your home is one of the most effective ways to start and keep a consistent workout habit. Renew Active includes a gym membership with over 22,000 gyms and fitness locations — so you’ll likely find plenty of options near you.',
  text2023Update:
    'Choosing a fitness location close to your home is one of the most effective ways to start and keep a consistent workout habit. Renew Active includes a gym membership with over 25,000 gyms and fitness locations — so you’ll likely find plenty of options near you.',
  label: 'Enter ZIP Code',
};

export const getStarted = {
  title: 'Access an annual Personalized Fitness Plan.',
  subtitle: 'At no additional cost with Renew Active®',
  content: [
    {
      title: '',
      text: "You'll receive a one-on-one session with a personal trainer to set fitness-related goals and create an annual personalized fitness plan.",
    },
    {
      title: 'Getting started is easy.',
      text: "Whether trying the gym for the first time or introducing something new to your existing fitness routine, Renew Active helps you stay ready for what's next.",
    },
    {
      title: 'Stay the course.',
      text: 'Meet with a personal trainer to track your progress every calendar year.',
    },
  ],
};

export const stepUp = {
  title: 'Step up to fitness classes for every interest and ability.',
  subtitle: '',
  content: [{ title: '', text: 'Explore a whole range of new experiences. Who knew working out could be this fun?' }],
  superScript: '1',
};
