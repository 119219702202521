import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components/macro';
import { AppState, page as pageStore } from '@rally/renew-active/store';
import { ButtonLink } from '@rally/rewards-view/component';
import { MobileNavBackground } from '@rally/rewards-view/color';
import { PageName } from '@rally/renew-active/hoc';
import { Adobe } from '@rally/renew-active/constant';
import PagePath from '../constants/PagePath';

const MobileNav = React.memo(() => {
  const dispatch = useDispatch();
  const page = useSelector((state: AppState) => state.page.page);
  const showNav = useSelector((state: AppState) => state.page.showNav);
  const toggleNav = useCallback(() => dispatch(pageStore.actions.toggleNav()), [dispatch]);
  const ariaCurrent = (p: PageName) => (p === page ? 'page' : undefined);
  const isSelected = (s: string): boolean => s === page;
  const tabIndex = showNav ? 0 : -1;

  return (
    <>
      <CloseContainer>
        <NavToggleButton aria-label="close" onClick={toggleNav} role="button" tabIndex={tabIndex}>
          <img alt="" src={require('../assets/close.svg')} />
        </NavToggleButton>
      </CloseContainer>
      <ButtonLink
        aria-current={ariaCurrent('Home')}
        data-testid="home"
        linkName={Adobe.navHome.prospect}
        onClick={toggleNav}
        selected={isSelected('Home')}
        tabIndex={tabIndex}
        theme="mobileV2"
        to={PagePath.Home}
      >
        Home
      </ButtonLink>
      <ButtonLink
        aria-current={ariaCurrent('Benefits')}
        data-testid="benefits"
        linkName={Adobe.navBenefits}
        onClick={toggleNav}
        selected={isSelected('Benefits')}
        tabIndex={tabIndex}
        theme="mobileV2"
        to={PagePath.FitnessFeatures}
      >
        Fitness Location Features
      </ButtonLink>
      <ButtonLink
        aria-current={ariaCurrent('Location')}
        data-testid="location"
        linkName={Adobe.navLocation}
        onClick={toggleNav}
        selected={isSelected('Location')}
        tabIndex={tabIndex}
        theme="mobileV2"
        to={PagePath.GymSearch}
      >
        Find a Fitness Location
      </ButtonLink>
      <ButtonLink
        aria-current={ariaCurrent('Classes')}
        data-testid="classes"
        linkName={Adobe.navClasses}
        onClick={toggleNav}
        selected={isSelected('Classes')}
        tabIndex={tabIndex}
        theme="mobileV2"
        to={PagePath.Classes}
      >
        Classes, Clubs &amp; Events Near You
      </ButtonLink>
      <ButtonLink
        aria-current={ariaCurrent('Brain')}
        data-testid="brain"
        linkName={Adobe.navBrain}
        onClick={toggleNav}
        selected={isSelected('Brain')}
        tabIndex={tabIndex}
        theme="mobileV2"
        to={PagePath.Brain}
      >
        Exercise Your Mind
      </ButtonLink>
      <ButtonLink
        aria-current={ariaCurrent('Fitbit')}
        data-testid="fitbit"
        linkName={Adobe.navFitbit}
        onClick={toggleNav}
        selected={isSelected('Fitbit')}
        tabIndex={tabIndex}
        theme="mobileV2"
        to={PagePath.Fitbit}
      >
        Join Our Fitbit Community
      </ButtonLink>
      <Blank />
    </>
  );
});

const CloseContainer = styled.div`
  align-items: center;
  display: flex;
  height: 70px;
  justify-content: center;
  width: 55px;
`;

const NavToggleButton = styled.button`
  background-color: ${MobileNavBackground};
  border: none;
  cursor: pointer;
`;

const Blank = styled.div`
  background-color: ${MobileNavBackground};
  flex: 1 1 auto;
`;

// To fix eslint error for react/display-name
MobileNav.displayName = 'MobileNav';

export default MobileNav;
