import React from 'react';
import styled from 'styled-components/macro';
import { UHCDarkText, UHCLinkV2 } from '@rally/rewards-view/color';
import { ContentRow } from '@rally/rewards-view/component';
import { breakpoint } from '@rally/rewards-util';
import { page } from '@rally/renew-active/hoc';
import { Adobe, config } from '@rally/renew-active/constant';
import { FeatureIconsSet, FeaturesLocationSearch, SectionInfo } from '@rally/renew-active/component';
import { PageTitle } from './shared';
import { activateBody, didYouKnow, getStarted, pageTitle, stepUp } from '../constants/benefitsText';
import heroImgV2 from '../assets/benefits/benefitsHeroV2.jpg';
import heroImgV2Mobile from '../assets/benefits/benefitsHeroV2Mobile.jpg';
import getStartedV2 from '../assets/benefits/getStarted.jpg';
import getStartedV2Mobile from '../assets/benefits/getStartedMobile.jpg';

type didYouKnowKey = keyof typeof didYouKnow;

const Benefits = () => {
  const renewActive2023CopyUpdates = config.renewActive2023CopyUpdates;
  const textSuffix = renewActive2023CopyUpdates ? '2023Update' : '';
  return (
    <Container>
      <TopContentRow>
        <></>
        <BenefitsPageTitle text={pageTitle} />
      </TopContentRow>
      <ActiveContent>
        <ActivateBodyContentRow>
          <SectionInfo {...activateBody} />
          <FeaturesLocationSearch
            label={didYouKnow.label}
            linkName={Adobe.benefitsFindLocation}
            locationPath="/location"
          >
            <DYKTitle>{didYouKnow.title}</DYKTitle>
            <DYKText>{didYouKnow[`text${textSuffix}` as didYouKnowKey]}</DYKText>
            <LabelTitle>{didYouKnow.labelTitle}</LabelTitle>
          </FeaturesLocationSearch>
        </ActivateBodyContentRow>
      </ActiveContent>
      <GetStartedContentRow>
        <SectionInfo {...getStarted} />
        <></>
      </GetStartedContentRow>
      <BottomContentTitleRow>
        <SectionInfo {...stepUp} />
        <></>
      </BottomContentTitleRow>
      <FeatureIconsWrap>
        <FeatureIconsSet />
      </FeatureIconsWrap>
      <BottomContentRow>
        <FinePrint>
          <sup>1</sup> Classes vary by fitness location.
        </FinePrint>
      </BottomContentRow>
    </Container>
  );
};

const BenefitsPageTitle = styled(PageTitle)`
  @media ${breakpoint.TABLET_OR_LARGER} {
    h1 {
      margin-left: 0;
      margin-top: 0;
      max-width: 500px;
      width: 140%;
    }
  }

  @media ${breakpoint.TABLET} {
    h1 {
      margin-top: -20px;
    }
  }

  @media ${breakpoint.MOBILE} {
    h1 {
      margin-top: 24px;
    }
  }
`;

const ActiveContent = styled.div`
  position: relative;
  z-index: 2;

  label p.form-label {
    color: ${UHCDarkText};
    font-size: 17px;
    font-weight: normal;
    line-height: 20px;
    margin-bottom: 5px;
  }
`;

const DYKTitle = styled.h3`
  color: ${UHCLinkV2};
  font-size: 24px;
  margin: 13px 0 7px 0;

  @media ${breakpoint.TABLET} {
    font-size: 20px;
  }

  @media ${breakpoint.MOBILE} {
    font-size: 18px;
  }
`;

const DYKText = styled.p`
  color: ${UHCLinkV2};
  font-size: 17px;
  font-weight: 300;
  margin: 5px 0; /* override default */
  padding-bottom: 30px;

  @media ${breakpoint.TABLET} {
    font-size: 15px;
  }

  @media ${breakpoint.MOBILE} {
    font-size: 14px;
    margin: 0 0 10px 0; /* override default */
  }
`;

const LabelTitle = styled.h4`
  color: ${UHCLinkV2};
  font-size: 19px;
  font-weight: bold;
  line-height: 23px;
  margin: 0;
  padding-bottom: 5px;
`;

const FinePrint = styled.p`
  color: ${UHCLinkV2};
  font-size: 15px;
  font-weight: 300;
  margin-top: -10px;
  text-align: left;
  width: 100%;

  @media ${breakpoint.TABLET_OR_LARGER} {
    padding-left: 20px;
  }
`;

const TopContentRow = styled(ContentRow)`
  background: white url(${heroImgV2}) no-repeat top left;
  background-size: 100% auto;
  height: 346px;
  z-index: 2;

  @media ${breakpoint.TABLET} {
    background-size: auto 100%;
    height: 225px;
  }

  @media ${breakpoint.MOBILE} {
    background-image: url(${heroImgV2Mobile});
    background-size: cover;
    height: 377px;
    margin: 0;
  }
`;

const ActivateBodyContentRow = styled(ContentRow)`
  background-color: white;
`;

const GetStartedContentRow = styled(ContentRow)`
  background: white url(${getStartedV2}) no-repeat center right;
  background-size: auto 100%;
  padding: 50px 0 60px 0;
  z-index: 2;

  @media ${breakpoint.TABLET} {
    background-position: left 60% center;
  }

  @media ${breakpoint.MOBILE} {
    background-image: url(${getStartedV2Mobile});
    background-position: bottom center;
    padding-bottom: 450px;
  }
`;

const BottomContentRow = styled(ContentRow)`
  z-index: 3;
`;

const BottomContentTitleRow = styled(BottomContentRow)`
  margin-top: -50px;

  @media ${breakpoint.MOBILE} {
    margin-top: 0;
  }
`;

const Container = styled.div`
  overflow: hidden;
`;

const FeatureIconsWrap = styled.div`
  margin: 0 20px;
`;

export default page(Benefits, 'Benefits');
